<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h5 class="mt-1 mb-0">
              Logs for Job: {{ $route.params.id }}
            </h5>
            <button
              class="btn btn-sm btn-primary"
              type="button"
              @click="$router.push(`/jobs`)"
            >
              Back to Jobs
            </button>
          </div>
          <div
            class="card-body"
            style="height: 38em"
          >
            <div class="text-muted mb-2">
              Total count: {{ totalElements }}
            </div>
            <ag-grid-vue
              :columnDefs="columnsJobLogs"
              :defaultColDef="defaultColDef"
              :rowData="rowsJobLogs"
              class="ag-theme-alpine"
              style="width: 100%; height: 95%;"
              @grid-ready="loadJobLogs"
            />
          </div>
          <div class="card-footer">
            <PaginationBar
              :totalPages="totalPages"
              :initialSelectedSize="selectedSize"
              @paginationChanged="paginationChangedHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { AgGridVue } from 'ag-grid-vue3';
import { jobLogsFieldMapping } from '@/components/Jobs/constants';
import PaginationBar from '@/components/common/PaginationBar';

export default {
  name: 'JobLogs',
  components: {
    PaginationBar,
    AgGridVue
  },
  data() {
    return {
      selectedSize: 10,
      currentPage: 1,
      data: {}
    };
  },
  computed: {
    defaultColDef() {
      return  {
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
      };
    },
    columnsJobLogs() {
      return [
        { field: jobLogsFieldMapping.id, width: 90 },
        { field: jobLogsFieldMapping.createdAt },
        {
          field: jobLogsFieldMapping.status,
          width: 110,
          cellRenderer: (params) => {
            const cellClass = {
              CREATED: '',
              FAILED: 'text-danger',
              FINISHED: 'text-success',
              IN_PROCESS: 'fst-italic'
            };
            return `<span class="${cellClass[params.value] || ''}">${params.value}</span>`;
          }
        },
        { field: jobLogsFieldMapping.startDate },
        { field: jobLogsFieldMapping.endDate },
        { field: jobLogsFieldMapping.customParams, width: 270 },
        { field: jobLogsFieldMapping.createdBy },
        { field: jobLogsFieldMapping.updatedAt }
      ];
    },
    rowsJobLogs() {
      return _.map(this.data?.content || [], (log) => {
        const result = {};
        result[jobLogsFieldMapping.id] = log.id;
        result[jobLogsFieldMapping.createdAt] = log.createdAt;
        result[jobLogsFieldMapping.updatedAt] = log.updatedAt;
        result[jobLogsFieldMapping.startDate] = log.startDate;
        result[jobLogsFieldMapping.endDate] = log.endDate;
        result[jobLogsFieldMapping.status] = log.status || '';
        result[jobLogsFieldMapping.customParams] =  _.isEmpty(log.customParams) ? '-' : JSON.stringify(log.customParams);
        result[jobLogsFieldMapping.createdBy] = log.createdBy || '';
        return result;
      });
    },
    totalPages() {
      return this.data?.totalPages || 1;
    },
    totalElements() {
      return this.data?.totalElements || 0;
    }
  },
  methods: {
    ...mapActions({
      fetchJobLogs: 'jobLogs/fetchLogs'
    }),
    async loadJobLogs() {
      try {
        this.data = await this.fetchJobLogs({
          jobId: this.$route.params.id,
          currentPage: this.currentPage,
          selectedSize: this.selectedSize
        });
      } catch {
        this.$toast.show('Failed to load job logs.', { type: 'error' });
      }
    },
    async paginationChangedHandler(e) {
      this.selectedSize = e.selectedSize;
      this.currentPage = e.currentPage;
      await this.loadJobLogs();
    }
  }
};
</script>
<style>
.pagination__wrapper {
  display: flex;
  flex-direction: row;
}
.pagination__scrollbar {
  max-width: 500px;
  overflow: hidden;
  overflow-x: auto;
}

.pagination__scrollbar::-webkit-scrollbar {
  height: 10px;
}

.pagination__scrollbar::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.pagination__scrollbar::-webkit-scrollbar-thumb {
  background: #e1e1e1;
}
</style>
