import _ from 'lodash';

export const aggregationJobs = [
  'AGGREGATION',
  'REAGGREGATION',
];

export const slaJobs = [
  'SLA_FULFILMENT',
  'SLA_FULFILMENT_RECALCULATION',
  'SLA_RECEIVING',
  'SLA_RECEIVING_RECALCULATION',
  'SLA_HOLIDAYS',
  'SLA_LAST_TOUCH_MANUFACTURING',
  'SLA_LAST_TOUCH_MANUFACTURING_RECALCULATION',
  'SLA_RECEIVING_V2',
  'SLA_RECEIVING_V2_RECALCULATION'
];

export const forecastJobsHash = {
  FORECAST_MISMATCH: 'FORECAST_MISMATCH',
  FORECAST_WHOLESALE: 'FORECAST_WHOLESALE',
  FORECAST_LACK: 'FORECAST_LACK',
  FORECAST_STATS_UPDATE: 'FORECAST_STATS_UPDATE',
  FORECAST_LOCK: 'FORECAST_LOCK'
};

export const forecastJobs = _.keys(forecastJobsHash);

export const configurableJobs = [
  ...aggregationJobs,
  ...slaJobs,
  ...forecastJobs
];

export const shipheroCalculationOptions = [
  { key: null, value: 'Default' },
  { key: 'true', value: 'Yes' },
  { key: 'false', value: 'No' }
];

export const jobLogsFieldMapping = {
  id: '#',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
  startDate: 'Start date',
  status: 'Status',
  endDate: 'End date',
  customParams: 'Custom params',
  createdBy: 'Created by'
};

export const transimpactJobs = [
  'TRANSIMPACT',
  'TRANSIMPACT_REAGGREGATION',
  'TRANSIMPACT_REPORT'
];

export const jobStatuses = {
  CREATED: 'CREATED',
  FAILED: 'FAILED',
  FINISHED: 'FINISHED',
  IN_PROCESS: 'IN_PROCESS'
};

export const trailingPeriodCalculationOptions = [
  { key: 'day', value: 'day' },
  { key: 'week', value: 'week' },
  { key: 'month', value: 'month' }
];

export const shippingCarrierJobs = [
  'SHIPPING_CARRIERS_FILES_FROM_SFTP_TO_S3',
];
